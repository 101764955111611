@import "../../../data/styles.css";

.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
}

.nav-background {
	width: 380px;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: #fff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
}

.nav-list {
	display: flex;
	justify-content: space-around;
	list-style: none;
	height: 100%;
	padding: 0;
}

.nav-item {
	display: grid;
	align-items: center;
	font-weight: bold;
	font-size: 0.9em;
}

.nav-item.active {
	color: var(--theme-primary) !important;
}

.nav-item:hover {
	color: var(--theme-primary);
	transition: color 0.3s ease-in-out;
	cursor: pointer;
}

@media (max-width: 600px) {
	.navbar {
		width: 80%;
		font-size: 80%;
	}

	.nav-background {
		width: 80%;
		height: 40px;
	}

	.nav-item {
		font-size: 1em;
	}
}
