@import "../../data/styles.css";

.homepage-logo-container {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 130px;
}

.homepage-header {
	display: flex;
	align-items: flex-end;
	gap: 20px;
}

.homepage-container {
	padding-top: 25px;
	padding-bottom: 50px;
}

.homepage-after-title {
	display: flex;
}

.homepage-projects {
	flex-basis: 300px;
	padding-top: 40px;
}

.homepage-experience {
	flex-basis: 600px;
	padding-top: 140px;
}

.homepage-title {
	transition: opacity 0.3s ease;
}

@media (max-width: 1024px) {
	.homepage-first-area {
		flex-direction: column;
	}

	.homepage-title {
		font-size: 40px;
		width: 100% !important;
		margin-top: 20px;
		order: 2;
	}

	.homepage-logo-container {
		width: 80px !important;
	}

	.homepage-first-area-left-side {
		display: flex;
		flex-direction: column; /* Changed: set direction to column */
		align-items: flex-start;
		width: 100%;
		order: 2;
	}

	.homepage-title-secondary {
		width: 100% !important;
		order: 3;
	}

	.homepage-subtitle {
		width: 100% !important;
		margin-top: 10px;
		order: 4;
	}

	.homepage-first-area-right-side {
		width: 100%;
		order: 1;
	}

	.homepage-image-container {
		padding-top: 50px;
		padding-bottom: 50px;
		margin-left: 10px;
		order: 1;
	}

	.homepage-after-title {
		flex-direction: column;
	}

	.homepage-projects {
		flex-basis: auto;
	}

	.homepage-experience {
		padding-top: 70px;
	}
}

@media (max-width: 800px) {
	.homepage-experience {
		margin: 0;
	}

	.homepage-title {
		font-size: 40px !important;
	}
}
