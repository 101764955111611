@import "../../../data/styles.css";

.works-body {
	margin-bottom: 10px;
	display: flex;
}

.works-right {
	flex: 2;
}

.card-icon {
	font-size: 20px;
	color: var(--tertiary);
}

.card-header {
	display: flex;
	align-items: center;
	padding-bottom: 20px;
}

.card-title {
	color: var(--secondary);
	font-size: 14px;
	padding-left: 20px;
	font-weight: 600;
}

@media (max-width: 800px) {
	.works-body {
		display: flex;
		flex-direction: column;
	}

	.works-right {
		padding-top: 20px;
	}
}
