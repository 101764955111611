@import "../../../data/styles.css";

.container {
	flex: 1;
	padding-right: 20px;
}

.exp {
	padding: 15px;
	display: flex;
	gap: 12px;
	border-radius: 10px;
}

.exp:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
	cursor: pointer;
}

.exp:hover .exp-title {
	color: var(--theme-primary);
	transition: color 0.3s ease-in-out;
}

.exp-active {
	background: #fafafa;
}

.exp-active .exp-title {
	color: var(--theme-primary);
}

.exp-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.exp-title {
	font-size: 15px;
	font-weight: 700;
	color: var(--secondary);
}

.exp-subtitle {
	font-size: 12px;
	color: var(--secondary);
}

.exp-duration {
	font-size: 12px;
	color: var(--tertiary);
}

.exp-right {
	width: 100%;
}

.exp-position {
	display: flex;
	justify-content: space-between;
	padding-right: 10px;
}
