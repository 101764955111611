@import "../../../data/styles.css";

.dt-timeline-header {
	font-family: var(--secondary-font);
	color: var(--primary);
	font-size: 16px;
	padding-bottom: 5px;
	font-weight: 400;
}

.dt-timeline-list {
	padding-top: 4px;
	padding-left: 20px;
}
