:root {
	--primary: #27272a;
	--secondary: #65656d;
	--tertiary: #acacb4;
	--theme-primary: #00ccff;
	--theme-secondary: #5a66ff;

	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
}
