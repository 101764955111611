@import "../../../data/styles.css";

.skills-image {
	height: 375px;
	width: 375px;
}

.skills-container {
	padding-top: 70px;
	display: flex;
}

.left {
	/* border: 1px solid red; */
	flex-shrink: 1;
}

.right {
	/* border: 1px solid blue; */
	flex-grow: 1;
	margin: 20px;
}

.skills-section {
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;
}

.skills-title {
	font-size: 20px;
	font-weight: 700;
	color: var(--theme-secondary);
}

.skills-tag-group {
	margin: 20px 0 0 0;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.skills-tag {
	margin-left: 0 !important;
}

@media (max-width: 760px) {
	.skills-container {
		display: block;
	}

	.left {
		display: grid;
		justify-content: center;
	}

	.right {
		display: flex;
		flex-direction: column;
		padding-top: 40px;
		gap: 20px;
		margin: 20px 0;
	}
}

@media (max-width: 600px) {
	.skills-image {
		width: 100%;
		height: 100%;
	}
}
